import React, { useEffect, useState, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Box, Typography } from '@material-ui/core'
import LinearProgress from '@mui/material/LinearProgress'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { loadStateFromLocalStorage } from '../../redux/utilsLocalStorage'

const useStyles = makeStyles(theme => ({
  customSubtitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '30px',
  },
}))

const PortfolioHistoryChart = props => {
  const classes = useStyles(props)
  const { chartData, chartName, selectedSetup, setTrackDisableStatus } = props
  const [chartIsReady, setChartIsReady] = useState(false)
  const containerRef = useRef(null)

  const timezone =
    loadStateFromLocalStorage('timezone') !== undefined
      ? loadStateFromLocalStorage('timezone')
      : 'Etc/UTC'

  const createLineSeries = ({
    chart,
    root,
    name,
    xAxis,
    yAxis,
    valueXField,
    valueYField,
    fill,
    stroke,
    tooltipText,
    strokeWidth,
    bulletRadius,
    data,
  }) => {
    const lineSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: valueXField,
        valueYField: valueYField,
        ...(fill && { fill: fill }),
        ...(stroke && { stroke: stroke }),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: tooltipText,
        }),
      }),
    )

    lineSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: bulletRadius || 5, // default radius is 5
          fill: lineSeries.get('fill'),
        }),
      })
    })

    lineSeries.strokes.template.set('strokeWidth', strokeWidth || 2.5) // default stroke width is 2.5
    lineSeries.data.setAll(data)

    return lineSeries
  }

  const createMultipleLineSeries = (chart, root, seriesConfigs) => {
    const seriesArray = seriesConfigs.map(config => {
      return createLineSeries({
        chart: chart,
        root: root,
        name: config.name,
        xAxis: config.xAxis,
        yAxis: config.yAxis,
        valueXField: config.valueXField,
        valueYField: config.valueYField,
        fill: config.fill,
        stroke: config.stroke,
        tooltipText: config.tooltipText,
        strokeWidth: config.strokeWidth,
        bulletRadius: config.bulletRadius,
        data: unitConversion(config.data),
      })
    })

    return seriesArray
  }

  const unitConversion = data => {
    /* For now receiving data in kw and transform to MW*/
    return data.map(el => ({
      ...el,
      sum_InstalledCapacityKW: el.sum_InstalledCapacityKW / 1000,
    }))
  }

  useEffect(() => {
    const initializeChart = () => {
      try {
        if (typeof am5.registry.rootElements !== 'undefined') {
          am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id === chartName) {
              root.dispose()
            }
          })
        }
        const root = am5.Root.new('chartPortfolioHistory')
        root.setThemes([am5themes_Animated.new(root)])
        root._logo.dispose()
        root.timezone = am5.Timezone.new(timezone)

        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            wheelY: 'zoomX',
            /* Disabling this option ensures that labels for all series will be shown on the chart. */
            // maxTooltipDistance: 0,
            layout: root.verticalLayout,
          }),
        )

        // Create Y-axis - installed capacity
        const yInstalledCapacity = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            numberFormat: '#.',
            extraTooltipPrecision: 1,
            renderer: am5xy.AxisRendererY.new(root, {}),
            min: 0,
          }),
        )

        // Create Y-axis - number of plants
        const yNumberOfPlants = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            maxPrecision: 0,
            syncWithAxis: yInstalledCapacity,
            renderer: am5xy.AxisRendererY.new(root, {
              opposite: true,
            }),
          }),
        )

        const installedCapacityLabel = am5.Label.new(root, {
          rotation: -90,
          text: 'Installed capacity (MW)',
          y: am5.p50,
          centerX: am5.p50,
          //x: am5.p0,
          //centerY: am5.p0
        })

        yInstalledCapacity.children.unshift(installedCapacityLabel)

        const numberOfPlantsLabel = am5.Label.new(root, {
          rotation: -90,
          text: 'Number of Plants',
          y: am5.p50,
          centerX: am5.p50,
          x: am5.p100,
        })

        yNumberOfPlants.children.unshift(numberOfPlantsLabel)

        // Create X-Axis - time
        const xAxisTime = chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            groupData: false,
            y: am5.percent(20),
            extraTooltipPrecision: 1,
            minGridDistance: 20,
            tooltipDateFormat: 'yyyy-MM-dd HH:mm',
            extraMin: 0.04,
            extraMax: 0.04,
            maxDeviation: 0.5,
            baseInterval: { timeUnit: 'minute', count: 1 },
            renderer: am5xy.AxisRendererX.new(root, {}),
          }),
        )
        let timeLabel = am5.Label.new(root, {
          text: timezone,
          x: am5.percent(50),
          // y: am5.p100,
        })

        xAxisTime.children.push(timeLabel)
        xAxisTime.get('dateFormats')['month'] = 'yyyy-MMM-dd'
        xAxisTime.get('dateFormats')['day'] = 'yyyy-MM-dd\nHH:mm'
        xAxisTime.get('dateFormats')['hour'] = 'yyyy-MM-dd\nHH:mm'
        xAxisTime.get('dateFormats')['minute'] = 'yyyy-MM-dd\nHH:mm'

        // disable vertical lines
        xAxisTime.get('renderer').grid.template.setAll({
          strokeOpacity: 0,
        })

        /**
         * This was used to format TimeUTC in tooltioText
         * TimeUTC: [bold]{valueX.formatDate("yyyy-MM-dd HH:mm")}[/]\nInstalled Capacity: [bold]{valueY.formatNumber("#.")}
         */
        const seriesConfigs = [
          {
            name: 'Installed capacity (kW)',
            xAxis: xAxisTime,
            yAxis: yInstalledCapacity,
            valueXField: 'insertion_datetime',
            valueYField: 'sum_InstalledCapacityKW',
            fill: null,
            stroke: null,
            tooltipText:
              'Installed Capacity: [bold]{valueY.formatNumber("#.")}',
            strokeWidth: 2.5,
            bulletRadius: 5,
            data: chartData,
          },
          {
            name: 'Count BasedOn',
            xAxis: xAxisTime,
            yAxis: yNumberOfPlants,
            valueXField: 'insertion_datetime',
            valueYField: 'count_BasedOn',
            fill: am5.color(0xff0000),
            stroke: am5.color(0xff0000),
            tooltipText: 'Number of Plants: [bold]{valueY}',
            strokeWidth: 2.5,
            bulletRadius: 5,
            data: chartData,
          },
          {
            name: 'Count PlantId',
            xAxis: xAxisTime,
            yAxis: yNumberOfPlants,
            valueXField: 'insertion_datetime',
            valueYField: 'count_PlantId',
            fill: null,
            stroke: null,
            tooltipText: 'Number of Plants: [bold]{valueY}',
            strokeWidth: 2.5,
            bulletRadius: 5,
            data: chartData,
          },
        ]

        const seriesArray = createMultipleLineSeries(chart, root, seriesConfigs)

        chart.set(
          'cursor',
          am5xy.XYCursor.new(root, {
            behavior: 'zoomXY',
            xAxis: xAxisTime,
            /* In this way, all the labels are shown at once */
            snapToSeries: [seriesArray[0]],
          }),
        )

        xAxisTime.set(
          'tooltip',
          am5.Tooltip.new(root, {
            themeTags: ['axis'],
          }),
        )

        yInstalledCapacity.set(
          'tooltip',
          am5.Tooltip.new(root, {
            themeTags: ['axis'],
          }),
        )

        // Add legend
        const legend = chart.children.push(
          am5.Legend.new(root, {
            // fillField: 'color',
            // strokeField: 'color',
            layout: root.gridLayout,
            centerX: am5.percent(50),
            x: am5.percent(50),
            y: am5.percent(98),
          }),
        )
        legend.data.setAll(chart.series.values)

        // TODO - delete insertion_time on backend
        const formatedData = chartData.map(item => {
          const { insertion_time, ...rest } = item
          return rest
        })

        am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            // align: 'right',
            // valign: 'bottom',
          }),
          pdfOptions: { disabled: true },
          pdfdataOptions: { disabled: true },
          jsonOptions: { disabled: true },
          htmlOptions: { disabled: true },
          dateFields: ['insertion_datetime'],
          dateFormat: 'yyyy-MM-dd HH:mm',
          dataSource: formatedData,
          filePrefix: `${selectedSetup}_portfolio_history`,
        })

        // catch the event when chart is ready
        let timeout
        root.events.on('frameended', exportChart)
        function exportChart() {
          if (timeout) {
            clearTimeout(timeout)
          }
          timeout = setTimeout(function () {
            root.events.off('frameended', exportChart)
            setChartIsReady(true)
            setTrackDisableStatus(false)
          }, 100)
        }
      } catch (error) {
        console.error('Error in PortfolioHistoryChart:', error)
      }
    }

    if (chartData && containerRef.current && !chartIsReady) {
      setTimeout(() => {
        initializeChart()
      }, 100)
    }
  }, [chartData, chartIsReady, chartName])

  return (
    <>
      {!chartIsReady && (
        <Box sx={{ width: '100%', marginTop: '400px' }}>
          <Typography
            variant="h4"
            className={clsx(classes.formSubtitle, classes.customSubtitle)}
          >
            Generating graph
          </Typography>
          <LinearProgress />
        </Box>
      )}
      <div
        ref={containerRef}
        id={chartName}
        style={{
          width: '100%',
          height: '800px',
          marginTop: '50px',
          opacity: chartIsReady ? '1' : '0',
        }}
      ></div>
    </>
  )
}

export default PortfolioHistoryChart
